/**
 * @typedef { import("../types").FestivalEvent } FestivalEvent
 */
import React from 'react';
import * as styles from './ending-today.module.css';
import * as calendarStyles from '../calendar.module.css';
import { getDayName } from '../../../../utils/formatdate';
import { EventCard } from '../EventCard';
import { translate } from '../../../../utils/language';

/**
 * @param {{language: string; events: FestivalEvent[] }} props
 */
export function EndingToday({ events, language }) {
  if (events.length === 0) {
    return null;
  }

  return (
    <div className={styles['root']}>
      <h3 className={calendarStyles['sectionHeading']}>
        {translate(language, 'Ende von', 'End of')}
      </h3>

      <div>
        {events.map((event) => (
          <EventCard
            href={`/veranstaltung/${event.readable_id}`}
            title={event.Titel_Deutsch}
            language={language}
            a11yIcons={event.barrierefreiheits_eigenschaftens}
            description={getDescription(language, event)}
          />
        ))}
      </div>
    </div>
  );
}

/**
 * @param {string} language
 * @param {FestivalEvent} event
 */
function getDescription(language, event) {
  const location = translate(language, event.ort.Deutsch, event.ort.Englisch);

  const end = (() => {
    const dayName = getDayName(language, event.tag_ende.Datum).long;
    if (event.endTimeAsNumber === undefined) {
      return dayName;
    }
    if (event.endTimeAsNumber < 2) {
      return `${dayName} ${event.Uhrzeit_ende}`;
    }

    return event.Uhrzeit_ende;
  })();

  return `${location}, ${translate(language, 'bis', 'until')} ${end}`;
}
