/**
 * @typedef { import("../types").FestivalEvent } FestivalEvent
 */
import React from 'react';
import * as styles from './continuing-through-today.module.css';
import * as calendarStyles from '../calendar.module.css';
import { getDayName } from '../../../../utils/formatdate';
import { translate } from '../../../../utils/language';
import { EventCard } from '../EventCard';

/**
 * @param {{ language: string; events: FestivalEvent[] }} props
 * @returns
 */
export function ContinuingThroughToday({ events, language }) {
  if (events.length === 0) {
    return null;
  }

  return (
    <div className={styles['root']}>
      <h3 className={calendarStyles['sectionHeading']}>
        {translate(language, 'Durchgängig', 'Durational')}
      </h3>
      <div>
        {events.map((event) => (
          <EventCard
            href={`/veranstaltung/${event.readable_id}`}
            title={event.Titel_Deutsch}
            language={language}
            a11yIcons={event.barrierefreiheits_eigenschaftens}
            description={getDescription(language, event)}
          />
        ))}
      </div>
    </div>
  );
}

/**
 * @param {string} language
 * @param {FestivalEvent} event
 */
function getDescription(language, event) {
  const startDay = getDayName(language, event.tag.Datum).long;

  const endDay = getDayName(language, event.tag_ende.Datum).long;

  return `${startDay}, ${event.Uhrzeit} ${translate(
    language,
    'bis',
    'until'
  )} ${endDay} ${event.Uhrzeit_ende ? ', ' + event.Uhrzeit_ende : ''}`;
}
