import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../components/template"
import "../styles/programm.css"
import { programmContent } from "../content/programm"
import { SettingsContext } from "../contexts/settings"
import { graphql } from "gatsby"
import { imageUrl } from "../../utils/path"
import {translate} from "../../utils/language"
import {formatDate, formatDateLong} from "../../utils/formatdate"
import {renderMarkdown, renderMarkdownTitel} from "../../utils/rendermarkdown"
import { Calendar } from '../components/calendar';

function date (l, tag) {
    const localisedText = formatDate(l, tag.Datum)

    
    if(tag.Nummer) {
    return localisedText
    }
    
    return localisedText;    
}

function showMonth (l, tag, tag_ende) {
	const  dateWithMonth = formatDate(l, tag.Datum)


	if (tag.Datum === tag_ende.Datum) {
		return null
	} else {
		return `${dateWithMonth} - `
	}
}

const isDuringFestival = (element) => {
	const startDate = new Date("2021-05-20")
	const endDate =  new Date("2021-05-25")

	const eventDate = new Date(element.tag.Datum)

	return startDate <= eventDate && eventDate < endDate
}

const timeStringToNumber  = (timeString) => timeString ? parseInt(timeString.replace(/:/g, "")) :  timeString
 
const compareElements = (a,b) => {
	const dayCompare = a.tag.Datum.localeCompare(b.tag.Datum)
	if (dayCompare !== 0) {
		return dayCompare
	}
	
	return timeStringToNumber(a.Uhrzeit) - timeStringToNumber(b.Uhrzeit)
}


const ProgrammPage = ({data}) => {
    const [settings,] = useContext(SettingsContext)
    const content = programmContent[settings.language]
    const l = settings.language
    const sortedEvents = data.strapi.veranstaltungs.filter(element => element.tag).sort(compareElements)
	const eventsDuringFestival = sortedEvents.filter(isDuringFestival)
	const otherEvents = sortedEvents.filter((element) => !isDuringFestival(element))
    let lastKnownDate = null

	const displayEvent = showDate => v => {
		return <>
			{ !showDate? null : 
			<h2 className="heading-programm-h1">{ (() => {
				const text = formatDateLong(l, v.tag.Datum)
				if(text !== lastKnownDate) {
				lastKnownDate = text;
				return text;
				}
				return null;
	
	
				})() }
			</h2>}
			<h3 className="heading-programm-h2">
				<a className="link-to-project-page" href={`/veranstaltung/${v.readable_id}`} target="_blank" rel="noopener noreferrer">{renderMarkdownTitel(translate(l, v.Titel_Deutsch, v.Titel_Englisch))}</a>
			</h3>
			<div className="merkbox">
				{
					v.tag &&
					<div className="merkbox-element">
						<img className="icon-programm" src={settings.isHighContrast ? "/images/icons/Kalender_schwarz.png" : "/images/icons/Kalender.svg"} alt={translate(l, "Icon Kalender", "icon calender")}/>
						{showMonth(l, v.tag, v.tag_ende)}
						{date(l, v.tag_ende)}
					</div>
				}
				{
					v.Uhrzeit &&
					<div className="merkbox-element">
						<img className="icon-programm" src={settings.isHighContrast ? "/images/icons/Uhr_schwarz.png" : "/images/icons/Uhr.svg"} alt={translate(l, "Icon Zeit", "icon time")}/>
						{v.Uhrzeit}{translate(l, " Uhr", "")}
					</div>
				}
				{
					v.ort &&
					<div className="merkbox-element">
						<img className="icon-programm" src={settings.isHighContrast ? imageUrl(v.ort.Icon_kontrastreich.url) : imageUrl(v.ort.Icon.url)} alt={translate(l, "Icon Location", "icon location")}/>
						{translate(l, v.ort.Deutsch, v.ort.Englisch)}
					</div>
				}
				{
					v.veranstaltungsformat &&
					<div className="merkbox-element">
						<img className="icon-programm" src={settings.isHighContrast ? imageUrl(v.veranstaltungsformat.Symbol_kontrastreich.url) : imageUrl(v.veranstaltungsformat.Symbol.url)} alt={translate(l, "Icon Veranstaltungsformat", "icon event type")}/>
						{translate(l, v.veranstaltungsformat.Deutsch, v.veranstaltungsformat.Englisch)}
					</div>
				}
				{
					v.sprachen && v.sprachen.length !== 0 &&
					 v.sprachen.map(sprache => {	
						return <div className="merkbox-element">
						<img className="icon-programm" src={settings.isHighContrast ? "/images/icons/Sprache_schwarz.png" : "/images/icons/Sprache.svg"} alt={translate(l, "Icon Sprache", "icon language")}/>
						{translate(l, sprache.Deutsch, sprache.Englisch)}
					</div>
					
				})}
				{ v.barrierefreiheits_eigenschaftens.map(eigenschaften => {	
					return <div className="merkbox-element">
						<img className="icon-barrierefreiheit" src={settings.isHighContrast ? imageUrl(eigenschaften.Icon_dunkel.url) : imageUrl(eigenschaften.Icon.url)} alt={translate(l, "Icon Barriereeigenschaft", "icon accessibility feature")}/>
					</div>
				})}
				
			</div>
	
			<div className="mint-line"></div>
			<div className="pic-text-programm">
				
				{
					v.bild && v.bild.Bild &&
						<img className="pic-programm" src={imageUrl(v.bild.Bild.url)} alt={translate(l, v.bild.Deutsch, v.bild.English)}/>
					
				}
				
			  <div className="text-p">
				  {
					v.vorschautext &&
					<p>
					{renderMarkdown(translate(l, v.vorschautext.Deutsch, v.vorschautext.Englisch))}
					</p>
				  }
			  </div>
				
			</div>
			<div className="mint-line"></div>
		</>
		}

    return (
        <Template currentPage="schedule">
            <section className="end-of-site">
                <div>
                    <h1>{content.headline}
                        <a href="/programm" target="_blank" rel="noopener noreferrer" class="link-headline">&nbsp;&nbsp;{content.headlineSecond}</a>
                    </h1>
                    <div className="corona-info-container">
                        <h2 className="box-heading">{content.secondHeadlineCorona}</h2>
                        <p>
                            {content.coronaInfoFirst}
                        </p>
						<p>
                            {content.coronaInfoSecond}
                        </p>
                        <p>
                            {content.coronaInfoThirdFirst}
                            <Link className="link" to="/digitales">{content.coronaInfoThirdLink}</Link>
                            {content.coronaInfoThirdSecond}
                        </p>
						<p>
							{content.disclaimer}
						</p>
                    </div>
                    <div className="mint-line"></div>

					<h2>{translate(l, 'Übersicht', 'Overview')}</h2>
         			<Calendar veranstaltungen={data.strapi.veranstaltungs} language={l} />

					<h1>{translate(l, "Festival-Programm", "Festival Programme")}</h1>

                    <a className="link" href="https://issuu.com/nocturnalunrest/docs/210514_nou_programmheft" target="_blank" rel="noopener noreferrer">{translate(l, "Hier kannst du im Programmheft blättern", "Here you can browse through the programme")}</a>

					{ eventsDuringFestival.map(displayEvent(true)) }

					<h1>{translate(l, "Teaser-Programm", "Teaser Programme")}</h1>
                    { otherEvents.map(displayEvent(false)) }

                </div>
            </section>
        </Template>
    )
}

export default ProgrammPage

export const pageQuery = graphql`
query MyQuery {
	strapi {
	  veranstaltungs {
		readable_id
		vorschautext {
		  Deutsch
		  Englisch
		}
		barrierefreiheits_eigenschaftens {
			Icon {
				url
				formats
			}
			Icon_dunkel {
				url
				formats
			}
			Deutsch
			Englisch
		}
		bild {
			Deutsch
			Englisch
			Bild {
			  url
			}
		}
		sprachen {
			Deutsch
			Englisch
		}
		tag {
		  Datum
		  Nummer
		}
		tag_ende {
			Datum
		}
		ort {
			Icon {
			  url
			}
			Icon_kontrastreich {
			  url
			}
			Deutsch
			Englisch
		  }
		veranstaltungsformat {
		  Deutsch
		  Englisch
		  Symbol {
			url
		  }
		  Symbol_kontrastreich {
			url
		  }
		}
		Titel_Deutsch
		Titel_Englisch
		Uhrzeit
		Uhrzeit_ende
	  }
	}
  }
`;