
const programmContent = {
    german: {
        headline: "PROGRAMM ",
        secondHeadlineCorona: "Corona-Information",
        coronaInfoFirst: "Die Corona-Pandemie hat es uns nicht leicht gemacht zu planen! Bis vor kurzer Zeit hatten wir gehofft, dass es die Infektionszahlen Ende Mai ermöglichen, einen Teil des Programms von Nocturnal Unrest vor Ort stattfinden zu lassen. Angesichts der aktuellen Entwicklung der Corona-Pandemie haben wir nun entschieden, dass Nocturnal Unrest zum allergrößten Teil digital stattfinden wird. Bei der Digitalisierung der Formate werden wir weiterhin von unseren Spielstätten Künstlerhaus Mousonturm und Hafen 2 unterstützt (danke!).",
        coronaInfoSecond: "Während du diese Zeilen liest, arbeiten alle Beteiligten des Festivals arbeiten mit Hochdruck an der Digitalisierung ihrer Formate – wir vom nOu-Kollektiv möchten uns an dieser Stelle bei allen Künstler:innen, Wissenschaftler:innen, Expert:inenn, Techniker:innen und allen weiteren involvierten Personen für diese fulminante Arbeit bedanken! Wenn wir könnten, wir würden die Pandemie für euch stoppen!",
        coronaInfoThirdFirst: "Mehr zum digitalen Festival findest du unter ",
        coronaInfoThirdLink: "Digitales",
        coronaInfoThirdSecond: ". Wir freuen uns darauf, euch digital zu treffen!",
        disclaimer: "Das Programm ist in weiten Teilen ab 16 Jahren empfohlen.",
    },
    english: {
        headline: "PROGRAMME",
        secondHeadlineCorona: "Corona information",
        coronaInfoFirst: "The Corona pandemic has not made it easy for us to plan! Until recently, we had hoped that the infection figures at the end of May would allow part of the Nocturnal Unrest programme to take place locally. Given the current development of the Corona pandemic, we have now decided that Nocturnal Unrest will take place digitally for the very most part. For this endeavor, we will be supported by our venues Künstlerhaus Mousonturm and Hafen 2 in the digitalisation of the formats (thank you!).",
        coronaInfoSecond: "As you read these lines, all those involved in the festival are working full speed and full energy on the digitization of their projects – we, nOu collective, would like to take this opportunity to thank all the artists, scientists, experts, technicians and everyone else involved in this brilliant, and exhausting, process! If we could, we would stop the pandemic for you!",
        coronaInfoThirdFirst: "You can find out more about the digital festival at ",
        coronaInfoThirdLink: "Digital",
        coronaInfoThirdSecond: ". We look forward to meeting you digitally!",  
        disclaimer: "The programme is suitable for audiences from 16 years upwards.",
    }
}
export {programmContent}