/**
 * @typedef { import("../types").A11yIcon } A11yIcon
 */
import React from 'react';
import * as styles from './event-card.module.css';
import { renderMarkdownTitel } from '../../../../utils/rendermarkdown';
import { imageUrl } from '../../../../utils/path';
import { translate } from '../../../../utils/language';
import clsx from 'clsx';

/**
 * @param {{
 *  title: string,
 *  description: JSX.Element,
 *  href: string,
 *  prefix?: string,
 *  a11yIcons?: A11yIcon[],
 *  language: 'german' | 'english'
 * }} props
 */
export function EventCard({
  title,
  description,
  href,
  prefix,
  a11yIcons = [],
  language,
}) {
  const hasA11yIcons = a11yIcons.length !== 0;
  return (
    <a href={href} className={styles['root']}>
      {prefix && (
        <>
          <span className={styles['highlight']}>{prefix}</span>{' '}
        </>
      )}
      <span className={styles['title']}>{renderMarkdownTitel(title)}</span>
      {(description || hasA11yIcons) && (
        <span className={styles['descriptionWrapper']}>
          {description && (
            <span className={styles['descriptionWrapper']}>
              <span>&nbsp;— </span>
              <span className={styles['description']}>{description}</span>
            </span>
          )}

          {hasA11yIcons &&
            a11yIcons.map((icon) => (
              <A11yIconImage
                icon={icon}
                language={language}
                key={icon.Englisch}
              />
            ))}
        </span>
      )}
    </a>
  );
}

/**
 * @param {{icon: A11yIcon; language: 'german' | 'english'}} props
 */
function A11yIconImage({ icon, language }) {
  const urlBright = imageUrl(icon.Icon.formats.thumbnail.url);
  const urlDark = imageUrl(icon.Icon_dunkel.formats.thumbnail.url);

  const altText = translate(language, icon.Deutsch, icon.Englisch);

  return (
    <>
      <img
        src={urlBright}
        alt={altText}
        className={clsx(styles['a11yIcon'], styles['bright'])}
      />
      <img
        src={urlDark}
        alt={altText}
        className={clsx(styles['a11yIcon'], styles['dark'])}
      />
    </>
  );
}
