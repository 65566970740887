/**
 * @typedef { import("../types").FestivalEvent } FestivalEvent
 * @typedef { import("../types").Tab } Tab
 */
import React, { useState } from 'react';
import clsx from 'clsx';
import * as styles from './tabs.module.css';

/**
 * @param {{ defaultTabIndex?: number; tabs: Tab[] }} param0
 */
export function Tabs({ defaultTabIndex = 0, tabs }) {
  const [currentTab, setCurrentTab] = useState(defaultTabIndex);

  const id = useState(() => `id-${Math.random().toString(36)}`)[0];

  return (
    <div>
      <div className={styles['tabHeaderBar']} role="tablist">
        {tabs.map((tab, index) => {
          return (
            <button
              className={clsx(styles['tabHeaderButton'], {
                [styles['tabHeaderButtonActive']]: currentTab === index,
                [styles['tabHeaderButtonInactive']]: currentTab !== index,
              })}
              onClick={() => setCurrentTab(index)}
              aria-selected={currentTab === index ? true : false}
              aria-controls={`${id}-tabpanel`}
              role="tab"
              id={`${id}-tab${index}`}
            >
              <span className={styles['tabHeaderButtonTextShort']}>
                {tab.name.short}
              </span>
              <span className={styles['tabHeaderButtonTextLong']}>
                {tab.name.long}
              </span>
            </button>
          );
        })}
      </div>
      <div
        className={styles['tabContent']}
        id={`${id}-tabpanel`}
        role="tabpanel"
        aria-labelledby={`${id}-tab${currentTab}`}
      >
        {tabs[currentTab].content}
      </div>
    </div>
  );
}
