/**
 * @typedef { import("./types").FestivalEvent } FestivalEvent
 * @typedef { import("./types").TimeSlot } TimeSlot
 */

const festivalDates = [
  '2021-05-20',
  '2021-05-21',
  '2021-05-22',
  '2021-05-23',
  '2021-05-24',
];

/**
 * @param {string} day
 * @param {string} nextDay
 * @param {FestivalEvent[]} events
 */
function getEventsStartingOn(day, nextDay, events) {
  const startingToday = events.filter((event) => {
    const isToday = event.tag.Datum === day && event.startTimeAsNumber >= 2;
    const isTonight =
      event.tag.Datum === nextDay && event.startTimeAsNumber < 2;

    return isToday || isTonight;
  });

  /** @type {TimeSlot[]} */
  const slots = [];

  for (const event of startingToday) {
    if (slots.length === 0) {
      slots.push({
        timeSlot: event.startTimeAsNumber,
        events: [],
      });
    }

    let counter = 0;

    while (slots[slots.length - 1].timeSlot !== event.startTimeAsNumber) {
      const lastTimeSlot = slots[slots.length - 1].timeSlot;

      slots.push({
        timeSlot: (lastTimeSlot + 1) % 24,
        events: [],
      });

      counter++;
      if (counter > 200) {
        throw new Error('Unbounded while loop, event:', event, 'slots:', slots);
      }
    }

    slots[slots.length - 1].events.push(event);
  }

  return slots;
}

/**
 * @param {FestivalEvent[]} events
 */
function getEventsDuringFestival(events) {
  return events
    .filter((event) => {
      const hasDate = event.tag && event.tag.Datum;
      return hasDate && festivalDates.includes(event.tag.Datum);
    })
    .map((event) => {
      const startTimeAsNumber = Number(event.Uhrzeit.split(':')[0]);
      const endTimeAsNumber = event.Uhrzeit_ende
        ? Number(event.Uhrzeit_ende.split(':')[0])
        : undefined;
      return { ...event, startTimeAsNumber, endTimeAsNumber };
    })
    .sort((a, b) => {
      if (a.tag.Datum < b.tag.Datum) return -1;
      if (a.tag.Datum > b.tag.Datum) return 1;

      if (a.Uhrzeit < b.Uhrzeit) return -1;
      if (a.Uhrzeit > b.Uhrzeit) return 1;

      if (a.tag_ende.Datum < b.tag_ende.Datum) return -1;
      if (a.tag_ende.Datum > b.tag_ende.Datum) return 1;

      if (a.Uhrzeit_ende < b.Uhrzeit_ende) return -1;
      if (a.Uhrzeit_ende > b.Uhrzeit_ende) return 1;

      return 0;
    });
}

/**
 * @param {string} day
 * @param {FestivalEvent[]} events
 */
function getEventsContinuingThrough(day, events) {
  const continuingThroughToday = events.filter((event) => {
    const start = event.tag.Datum;
    const end = event.tag_ende ? event.tag_ende.Datum : event.tag.Datum;

    return start < day && end > day;
  });

  return continuingThroughToday;
}

/**
 * @param {string} day
 * @param {string} nextDay
 * @param {FestivalEvent[]} events
 */
function getEventsEndingOn(day, nextDay, events) {
  const endingToday = events.filter((event) => {
    const start = event.tag.Datum;
    const end = event.tag_ende ? event.tag_ende.Datum : event.tag.Datum;

    const startedBeforeToday =
      start < day || (start === day && event.startTimeAsNumber < 2);

    const endingToday =
      startedBeforeToday &&
      (event.endTimeAsNumber === undefined || event.endTimeAsNumber > 2) &&
      end === day;

    const endingTonight =
      startedBeforeToday && event.endTimeAsNumber < 2 && end === nextDay;

    return endingToday || endingTonight;
  });

  return endingToday;
}

/**
 * @param {FestivalEvent[]} events
 */
export function groupEvents(events) {
  const eventsDuringFestival = getEventsDuringFestival(events);

  const days = festivalDates.map((day, index, array) => {
    const nextDay = array[index + 1];

    const startingToday = getEventsStartingOn(
      day,
      nextDay,
      eventsDuringFestival
    );
    const continuingThroughToday = getEventsContinuingThrough(
      day,
      eventsDuringFestival
    );
    const endingToday = getEventsEndingOn(day, nextDay, eventsDuringFestival);

    return {
      date: new Date(Date.parse(day)),
      startingToday,
      continuingThroughToday,
      endingToday,
    };
  });

  return days;
}
