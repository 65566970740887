/**
 * @typedef { import("./types").FestivalEvent } FestivalEvent
 */

import { groupEvents } from './groupEvents';
import React from 'react';
import { StartingToday } from './StartingToday';
import { EndingToday } from './EndingToday';
import { ContinuingThroughToday } from './ContinuingThroughToday';
import * as styles from './calendar.module.css';
import { getDayName } from '../../../utils/formatdate';
import { Tabs } from './Tabs';

/**
 * @param {{ veranstaltungen: FestivalEvent[]; language: string }} param0
 */
export function Calendar({ veranstaltungen, language }) {
  const groupedByDay = groupEvents(veranstaltungen);

  const todayIndex = groupedByDay.findIndex((element) => isToday(element.date));

  const tabs = groupedByDay.map((day) => ({
    name: getDayName(language, day.date),
    content: (
      <div>
        <StartingToday slots={day.startingToday} language={language} />

        <ContinuingThroughToday
          events={day.continuingThroughToday}
          language={language}
        />

        <EndingToday events={day.endingToday} language={language} />
      </div>
    ),
  }));

  return (
    <div className={styles['calendar']}>
      <Tabs
        tabs={tabs}
        defaultTabIndex={todayIndex >= 0 ? todayIndex : undefined}
      />
    </div>
  );
}

/**
 * @param {Date} date
 */
function isToday(date) {
  const today = new Date();

  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  );
}
