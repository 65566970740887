/**
 * @typedef { import("../types").TimeSlot } TimeSlot
 */
import React from 'react';
import * as styles from './starting-today.module.css';
import * as calendarStyles from '../calendar.module.css';
import { getDayName } from '../../../../utils/formatdate';
import { EventCard } from '../EventCard';
import { translate } from '../../../../utils/language';

/**
 * @param {{ language: string; slots: TimeSlot[] }} props
 */
export function StartingToday({ slots, language }) {
  if (slots.length === 0) {
    return null;
  }

  return (
    <div className={styles['root']}>
      <h3 className={calendarStyles['sectionHeading']}>
        {translate(language, 'Beginn von', 'Start of')}
      </h3>
      <div>
        {slots.map((slot) => (
          <div className={styles['timeSlot']}>
            <div className={styles['time']}>{slot.timeSlot}:00</div>
            <div>
              {slot.events.map((event) => (
                <EventCard
                  href={`/veranstaltung/${event.readable_id}`}
                  prefix={
                    event.Uhrzeit.padStart(5, '0') !==
                    `${slot.timeSlot}:00`.padStart(5, '0')
                      ? event.Uhrzeit
                      : undefined
                  }
                  title={event.Titel_Deutsch}
                  language={language}
                  a11yIcons={event.barrierefreiheits_eigenschaftens}
                  description={getDescription(language, event)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

/**
 * @param {string} language
 * @param {FestivalEvent} event
 */
function getDescription(language, event) {
  const location = translate(language, event.ort.Deutsch, event.ort.Englisch);

  const end = [
    event.tag.Datum !== event.tag_ende.Datum &&
      getDayName(language, event.tag_ende.Datum).long,
    event.Uhrzeit_ende,
  ]
    .filter(Boolean)
    .join(', ');

  if (end) {
    return `${location}, ${translate(language, 'bis', 'until')} ${end}`;
  }
  return location;
}
